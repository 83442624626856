import React from "react";
import Routes from "./Routes";

import "./index.css"; //Tailwind
import "./styles/index.scss";

function App() {
  return <Routes />;
}
export default App;
