import React, { useEffect, useState } from "react";
import Icon from "../../components/Icon";

export default function Header({ location, isLuxury }) {
  const [activeMenu, setactiveMenu] = useState(false);
  const [sticky, setsticky] = useState("sticky-header");
  const [isAds, setisAds] = useState(false);
  useEffect(() => {
    if (window.scrollTop === 0) {
      setsticky("");
    } else {
      setsticky("sticky-header");
    }
  }, []);

  useEffect(() => {
    if (location?.search) {
      setisAds(location?.search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.href]);

  return (
  <></>
  );
}
