import React, { useEffect, useState } from "react";
import Header from "../Dashboard/Header";
import slider1 from "../../images/intro.png";
import slider2 from "../../images/luxury-slider.png";
import { Link } from "@reach/router";
import backbroundImage from "../../images/bg.jpeg";
import backbroundImage2 from "../../images/luxury-background.jpeg";
import Icon from "../../components/Icon";
import MessengerCustomerChat from "react-messenger-customer-chat/lib/MessengerCustomerChat";
import Fanpages from "../../components/Fanpages";

export default function Layout({ children, location }) {
  const [isLuxury, setisLuxury] = useState(false);
  useEffect(() => {
    if (location?.pathname === "/luxury-card") {
      setisLuxury(true);
    } else {
      setisLuxury(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    fetchTracking();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchTracking = async () => {
    const ads = location?.search.slice(5, location?.search.length);
    if (!ads) {
      return null;
    }

    try {
      await fetch(process.env.REACT_APP_CORE_API + "tracking/?ref=" + ads)
        .then((resp) => {})
        .then(function (data) {});
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="main-body">
      <Header location={location} isLuxury={isLuxury} />
      <main style={{ paddingTop: 0 }}>
        <div
          id="main-slider"
          style={{
            backgroundImage: `url(${
              isLuxury ? backbroundImage2 : backbroundImage
            })`,
          }}
          className="md:pt-16 md:pb-12 pt-0 pb-6"
        >
          <div className="container">
            <div className="flex flex-wrap">
              <div className="md:w-1/2 w-full">
                <div
                  className={
                    "pt-16 text-center " +
                    (isLuxury ? "text-black" : "text-white")
                  }
                >
                  {isLuxury ? (
                    <>
                      <div className="lg:text-4xl md:text-3xl text-2xl">
                        Thẻ Luxury
                        <br />
                        Sang Trọng - Chuyên Nghiệp
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="md:text-4xl text-3xl">
                        Ngừng nhận
                        <br /> đơn hàng mới
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="md:w-1/2 w-full">
                <div>
                  <img
                    className="mt-4 m-auto"
                    src={isLuxury ? slider2 : slider1}
                    style={{ maxWidth: 450, width: "100%" }}
                    alt="2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>{children}</div>
      </main>
      <section id="section7" className="lg:pt-10 py-10 bg-black text-white">
        <div className="container">
          <div className="">
            <div className="flex flex-wrap">
              <div className="md:w-1/3 w-full">
                <div className="mx-4  mt-4">
                  <div className="wrap-logo">
                    <a href="/">
                      <div
                        className="font-bold text-5xl text-logo"
                        style={{
                          letterSpacing: -5,
                          lineHeight: 1,
                          color: "white",
                        }}
                      >
                        metap
                      </div>
                    </a>
                  </div>
                 <div>Theo dõi Dũng Lại Lập Trình tại</div>
                  <div className="flex">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/dunglailaptrinh"
                    >
                      <Icon
                        type="facebook-f"
                        className="rounded-md flex justify-center items-center p-1"
                        style={{
                          width: 42,
                          height: 40,
                          color: "#fff",
                          backgroundColor: "#1d4b99",
                        }}
                      />
                    </a>
                  </div>
                  <span>&nbsp;</span>
                  <div className="text-sm">
                    <div>dunglailaptrinh@gmail.com</div>
                    <div>©2020 METAP</div>
                  </div>
                </div>
              </div>
              <div className="md:w-1/3 w-full">
                <div className="mx-4 text-sm" style={{ lineHeight: 1.7 }}>
                  <div className="mb-4 font-bold text-xl mt-4">
                    Thông tin liên hệ
                  </div>
                 	<div>Nhắn tin vào fanpage facebook Dũng Lại Lập Trình</div>
	       		<span>&nbsp;</span>
			<div>Thay đổi thông tin trên thẻ tại info.metap.vn</div>
                </div>
              </div>
              <div className="md:w-1/3 w-full">
              </div>
            </div>
          </div>
          <div className="mt-8">
            <Fanpages />
          </div>
        </div>
      </section>
    </div>
  );
}
