import React from "react";
import { Link } from "@reach/router";
export default function Service() {
  return (
    <div className="container mx-auto my-8">
      <div className="my-8 text-2xl font-bold text-center">
        ĐIỀU KHOẢN DỊCH VỤ
      </div>
      <div className="my-4">Chào mừng bạn đến với METAP!</div>
      <div className="my-4 font-bold text-lg">1. Giới thiệu</div>
      <div className="my-4">
        Metap là sản phẩm thẻ cá nhân thông minh cho phép bạn chia sẻ những kênh
        mạng xã hội của bạn cho người khác một cách nhanh chóng.{" "}
      </div>

      <div className="my-4">
        Các Điều khoản dịch vụ này phản ánh cách thức hoạt động của Metap, những
        điều luật mà chúng tôi phải tuân theo. Do đó, các Điều khoản dịch vụ này
        giúp xác định mối quan hệ giữa Metap với bạn khi bạn tương tác với các
        dịch vụ của chúng tôi. Việc hiểu rõ các điều khoản này là rất quan trọng
        vì bằng việc tải xuống, cài đặt, truy cập hoặc sử dụng Dịch vụ Metap,
        bạn đồng ý bị ràng buộc bởi các điều khoản này. Bên cạnh các điều khoản
        này, chúng tôi cũng ban hành
        <span className="text-blue-600">
          <Link to="/terms-privacy"> Chính sách bảo mật </Link>
        </span>{" "}
        và
        <span className="text-blue-600">
          <Link to="/terms-return"> Chính sách đổi trả </Link>
        </span>{" "}
        sẽ chi phối việc bạn sử dụng các dịch vụ của Metap. Nếu bạn không đồng
        ý, bạn vui lòng không cài đặt hoặc sử dụng bất kỳ Sản phẩm Metap nào
        hoặc truy cập Trang web. Khi quý khách hàng truy cập vào trang website
        của chúng tôi có nghĩa là quý khách đồng ý với các điều khoản này.{" "}
      </div>
      <div className="my-4">
        Chúng tôi có quyền, theo quyết định của chúng tôi, sửa đổi hoặc cập nhật
        các điều khoản của Điều khoản dịch vụ này bất kỳ lúc nào. Các thay đổi
        có hiệu lực ngay khi được đăng trên trang web mà không cần thông báo
        trước. Việc bạn tiếp tục sử dụng trang web sau đó có nghĩa là bạn đồng ý
        với những thay đổi đó và đồng ý bị ràng buộc bởi chúng. Hãy xem lại các
        Điều khoản và Chính sách Bảo mật của chúng tôi thường xuyên.
      </div>
      <div className="my-4 font-bold text-lg">
        2. Các loại trừ và giới hạn trách nhiệm
      </div>
      <div className="my-4">
        Chúng tôi nỗ lực cung cấp những Dịch vụ/Sản phẩm tốt nhất có thể và định
        ra các nguyên tắc rõ ràng cho mọi người dùng. Tuy nhiên, Dịch vụ/Sản
        phẩm của chúng tôi được cung cấp "nguyên trạng", những thông tin được
        cung cấp chỉ là cung cấp thông tin và tính khả dụng và chức năng của
        Dịch vụ Metap phụ thuộc vào nhiều yếu tố khác nhau, bao gồm phần mềm,
        phần cứng và mạng truyền thông do bên thứ ba cung cấp. Những yếu tố này
        không thể không có lỗi. Vì vậy, chúng tôi không đảm bảo hoặc tuyên bố
        với bạn điều gì liên quan đến Dịch vụ. Cụ thể, chúng tôi không tuyên bố
        hoặc đảm bảo với bạn rằng:
      </div>
      <ul>
        <li className="">
          <span className="pl-4"></span>• Việc bạn sử dụng dịch vụ sẽ đáp ứng
          các yêu cầu của bạn;
        </li>
        <li className="">
          <span className="pl-4"></span>• Việc bạn sử dụng Dịch vụ Metap sẽ
          không bị gián đoạn, không bị lỗi hoặc bị can thiệp, hoặc nó sẽ có thể
          truy cập được hay luôn sẵn sàng hoặc không thể bị truy cập trái phép;
          bất kỳ thông tin nào mà bạn nhận được từ việc bạn sử dụng dịch vụ sẽ
          chính xác và đáng tin cậy; và{" "}
        </li>
        <li className="">
          <span className="pl-4"></span>• Những khiếm khuyết về hoạt động hoặc
          chức năng của bất kỳ Sản phẩm nào được cung cấp cho bạn như là một
          phần của Dịch vụ sẽ được sửa chữa.{" "}
        </li>
      </ul>
      <div className="my-4">
        TRONG PHẠM VI TỐI ĐA ĐƯỢC PHÁP LUẬT CHO PHÉP ÁP DỤNG, METAP KHÔNG BẢO
        ĐẢM, DÙ TUYÊN BỐ RÕ RÀNG HAY MỘT CÁCH NGỤ Ý VỀ CHẤT LƯỢNG, HIỆU SUẤT,
        KHẢ NĂNG HOẠT ĐỘNG HOẶC SỰ PHÙ HỢP VỚI MỘT MỤC ĐÍCH CỤ THỂ CỦA CÁC DỊCH
        VỤ METAP. TRONG MỌI TRƯỜNG HỢP METAP KHÔNG CHỊU TRÁCH NHIỆM ĐỐI VỚI:
      </div>
      <div className="my-4">
        (i) NHỮNG RỦI RO, TỔN THẤT VỀ SỬ DỤNG, TỔN THẤT VỀ LỢI NHUẬN, TỔN THẤT
        VỀ DOANH THU, TỔN THẤT VỀ DỮ LIỆU, TỔN THẤT VỀ UY TÍN, HOẶC KHÔNG THỰC
        HIỆN ĐƯỢC CÁC BIỆN PHÁP NGUY CẤP DỰ TRÙ, ĐỐI VỚI TỪNG TRƯỜNG HỢP DÙ TRỰC
        TIẾP HOẶC GIÁN TIẾP; HOẶC
      </div>
      <div className="my-4">
        (ii) THIỆT HẠI GIÁN TIẾP, NGẪU NHIÊN, ĐẶC BIỆT HOẶC MANG TÍNH HỆ QUẢ NÀO
        (GỒM BẤT KỲ MẤT MÁT NÀO VỀ DỮ LIỆU, GIÁN ĐOẠN DỊCH VỤ, MÁY TÍNH, ĐIỆN
        THOẠI HOẶC CÁC THIẾT BỊ DI ĐỘNG KHÁC) PHÁT SINH TỪ HOẶC LIÊN QUAN ĐẾN
        VIỆC SỬ DỤNG TRANG WEB, DỊCH VỤ METAP, BAO GỒM THIỆT HẠI PHÁT SINH TỪ
        ĐÓ, NGAY CẢ KHI METAP ĐÃ ĐƯỢC THÔNG BÁO VỀ KHẢ NĂNG CỦA CÁC THIỆT HẠI ĐÓ
        HOẶC ĐƯỢC GỢI Ý PHẢI CHỊU TRÁCH NHIỆM.
      </div>
      <div className="my-4">
        NẾU BẠN KHÔNG HÀI LÒNG VỚI DỊCH VỤ METAP, BIỆN PHÁP DUY NHẤT LÀ DỪNG
        VIỆC SỬ DỤNG CÁC DỊCH VỤ VÀ (CÁC) SẢN PHẨM METAP. NGƯỜI SỬ DỤNG MIỄN TRỪ
        BẤT KỲ VÀ MỌI KHIẾU NẠI LIÊN QUAN ĐẾN METAP, VÀ LIÊN QUAN ĐẾN CÁC ĐẠI
        LÝ, ĐẠI DIỆN, NHÀ CUNG CẤP DỊCH VỤ VÀ NGƯỜI CẤP PHÉP PHÁT SINH KHỎI VIỆC
        BẠN SỬ DỤNG CÁC DỊCH VỤ METAP
      </div>
      <div className="my-4">
        CHÚNG TÔI CÓ THỂ THAY ĐỔI, TẠM DỪNG, RÚT LẠI HOẶC GIỚI HẠN VIỆC CUNG CẤP
        TẤT CẢ HOẶC BẤT KỲ PHẦN NÀO CỦA DỊCH VỤ METAP TẠI BẤT KỲ THỜI ĐIỂM NÀO
        MÀ KHÔNG CẦN CÓ THÔNG BÁO.
      </div>
      <div className="my-4 font-bold text-lg">3. Quyền sở hữu trí tuệ</div>
      <div className="my-4">
        Chúng tôi tôn trọng quyền sở hữu trí tuệ và yêu cầu bạn cũng phải làm
        như vậy. Như là một điều kiện cho việc bạn truy cập và sử dụng Dịch Vụ,
        bạn đồng ý không sử dụng Dịch vụ để xâm phạm bất kỳ quyền sở hữu trí tuệ
        nào. Các Dịch vụ Metap được bảo vệ bởi Luật sở hữu trí tuệ và các hiệp
        định, điều ước quốc tế về sở hữu trí tuệ. Metap và/hoặc người cấp phép
        của Metap sẽ giữ quyền sở hữu trong và đối với Dịch vụ Metap và tất cả
        quyền sở hữu trí tuệ liên quan, bao gồm nhưng không giới hạn nhãn hiệu,
        tên thương mại, quyền bản quyền, quyền cơ sở dữ liệu và bằng sáng chế.
        Bạn chỉ được cấp một quyền hạn chế để sử dụng Dịch vụ Metap theo Điều
        khoản này. Tất cả thương hiệu, nhãn hiệu dịch vụ, tên sản phẩm và tên
        thương mại của Metap xuất hiện trên hoặc thông qua Dịch vụ Metap đều
        thuộc sở hữu độc quyền bởi Metap.{" "}
      </div>
      <div className="my-4">
        TẠI BẤT KỲ THỜI ĐIỂM NÀO VÀ THEO QUYẾT ĐỊNH CỦA RIÊNG METAP, CÓ HOẶC
        KHÔNG CÓ THÔNG BÁO, CHÚNG TÔI BẢO LƯU QUYỀN TẠM NGỪNG HOẶC CHẤM DỨT TRUY
        CẬP VÀO DỊCH VỤ METAP CỦA BẤT KỲ NGƯỜI DÙNG NÀO MÀ XÂM PHẠM HOẶC ĐƯỢC
        CHO LÀ XÂM PHẠM BẤT KỲ BẢN QUYỀN HOẶC QUYỀN SỞ HỮU TRÍ TUỆ NÀO KHÁC.
      </div>
      <div className="my-4 font-bold text-lg">4. Cam kết của bạn</div>
      <div className="my-4">
        Việc bạn truy cập đến và sử dụng Dịch vụ, bạn phải tuân thủ các Điều
        khoản này và tất cả các quy định pháp luật hiện hành. Bạn cam kết và
        đồng ý rằng:
      </div>
      <ul>
        <li className="">
          <span className="pl-4"></span>• Bất kỳ và tất cả thông tin do bạn cung
          cấp, trong khi Đăng ký (nếu có, như được định nghĩa và chỉ rõ trong
          Chính sách quyền riêng tư của chúng tôi) là chính xác và được cập
          nhật.
        </li>
        <li className="">
          <span className="pl-4"></span>• Không sử dụng Sản phẩm/Dịch Vụ để đăng
          tải, truyền đưa, phân phối, lưu trữ hoặc cung cấp theo bất kỳ cách nào
          khác: các tệp có chứa vi-rút, trojan, sâu máy tính, bom lôgic hoặc các
          tài liệu khác mà có tính độc hại hoặc gây hại cho công nghệ; bất kỳ
          tài liệu quảng cáo, chào mời, quảng bá trái phép hoặc không mong muốn
          nào, “thư rác”, “gửi thư rác”, “chuỗi ký tự”, “sơ đồ kim tự tháp” hoặc
          bất kỳ hình thức chào mời bị cấm nào khác; bất kỳ thông tin cá nhân
          của bất kỳ bên thứ ba nào, bao gồm địa chỉ nhà, số điện thoại, địa chỉ
          email, số và đặc điểm trong tài liệu nhận diện cá nhân ; bất kỳ tài
          liệu nào mà xâm phạm hoặc có thể xâm phạm bất kỳ bản quyền, nhãn hiệu
          hoặc các quyền sở hữu trí tuệ hoặc quyền riêng tư nào khác của bất kỳ
          cá nhân nào; bất kỳ tài liệu nào mà lăng mạ người khác, tục tĩu, công
          kích, khiêu dâm, thù địch hoặc kích động; bất kỳ tài liệu nào mà có
          thể cấu thành, khuyến khích hoặc cung cấp các chỉ dẫn cho hành vi phạm
          tội hình sự, các hoạt động nguy hiểm hoặc tự gây hại; bất kỳ tài liệu
          nào mà được cố ý thiết kế để kích động hoặc phản đối người dân, đặc
          biệt là việc bêu xấu và bắt nạt, hoặc cố ý quấy rối, gây hại, gây
          thương tổn, dọa nạt, làm đau khổ, làm xấu hổ hoặc gây phiền muộn cho
          người khác; bất kỳ tài liệu nào mà có chứa bất kỳ loại đe dọa nào, bao
          gồm mối đe dọa về bạo lực thể xác; bất kỳ tài liệu nào mang tính phân
          biệt chủng tộc hoặc phân biệt đối xử, bao gồm việc phân biệt đối xử
          trên cơ sở chủng tộc, tôn giáo, tuổi tác, giới tính, khuyết tật hoặc
          tình dục của ai đó;
        </li>
        <li className="">
          <span className="pl-4"></span>• Không kết hợp Sản phẩm/Dịch Vụ hoặc
          bất kỳ phần nào của Sản phẩm/Dịch Vụ vào bất kỳ phần mềm hoặc sản phẩm
          nào khác mà không có sự đồng ý của chúng tôi. Trong trường hợp đó,
          chúng tôi bảo lưu quyền từ chối cung cấp dịch vụ, chấm dứt các tài
          khoản hoặc giới hạn việc truy cập vào Sản phẩm/Dịch Vụ theo quyết định
          của riêng chúng tôi;
        </li>
        <li className="">
          <span className="pl-4"></span>• Không can thiệp hoặc cố gắng can thiệp
          vào hoạt động đúng cách thức của Sản phẩm/Dịch Vụ, làm gián đoạn trang
          web của chúng tôi hoặc bất kỳ mạng lưới nào liên kết với Sản phẩm/Dịch
          Vụ, hoặc làm mất tác dụng bất kỳ phương pháp nào mà chúng tôi sử dụng
          để ngăn chặn hoặc giới hạn quyền truy cập vào Sản phẩm/Dịch Vụ;
        </li>
        <li className="">
          <span className="pl-4"></span>• Tháo rời, đảo ngược kết cấu, dịch
          ngược, giải mã hoặc cố gắng lấy mã nguồn của Sản phẩm/Dịch vụ Metap;
        </li>
        <li className="">
          <span className="pl-4"></span>• Sao chép, sửa đổi, cải tiến hoặc tạo
          các tác phẩm phái sinh của Sản phẩm hoặc bất kỳ phần nào của Sản phẩm;
        </li>
        <li className="">
          <span className="pl-4"></span>• Phá vỡ, vô hiệu hóa hoặc hoặc can
          thiệp vào các tính năng liên quan đến bảo mật của Dịch vụ/Sản phẩm
          Metap;
        </li>
      </ul>
      <div className="my-4">
        CHÚNG TÔI BẢO LƯU QUYỀN, TẠI BẤT KỲ THỜI ĐIỂM NÀO VÀ KHÔNG CẦN THÔNG BÁO
        TRƯỚC, GỠ BỎ HOẶC VÔ HIỆU HOÁ QUYỀN TRUY CẬP VÀO NỘI DUNG THEO QUYẾT
        ĐỊNH CỦA RIÊNG CHÚNG TÔI VÌ BẤT KỲ LÝ DO NÀO. CHÚNG TÔI CÓ THỂ GỠ BỎ
        HOẶC VÔ HIỆU HÓA QUYỀN TRUY CẬP VÀO NỘI DUNG VÌ MỘT SỐ LÝ DO MÀ CÓ THỂ
        BAO GỒM CẢ VIỆC NHẬN THẤY NỘI DUNG CÓ THỂ BỊ PHẢN ĐỐI, VI PHẠM CÁC ĐIỀU
        KHOẢN NÀY HOẶC CHÍNH SÁCH BẢO MẬT THÔNG TIN CỦA CHÚNG TÔI, VI PHẠM LUẬT
        HIỆN HÀNH, HOẶC CÓ THỂ GÂY THIỆT HẠI THEO CÁCH KHÁC CHO DỊCH VỤ HOẶC
        NGƯỜI DÙNG CỦA CHÚNG TÔI.
      </div>
      <div className="my-4 font-bold text-lg">5. Bồi thường thiệt hại</div>
      <div className="my-4">
        Bạn đồng ý bồi thường và giữ cho Metap và các công ty con, chi nhánh,
        cán bộ, đại lý, người cấp phép, đồng thương hiệu hoặc các đối tác khác
        và nhân viên, không bị tổn hại đối với bất kỳ và tất cả các yêu cầu,
        khiếu kiện, trách nhiệm, chi phí, và phí tổn, bao gồm, nhưng không giới
        hạn ở chi phí và phí tổn cho luật sư, phát sinh từ sự vi phạm của bạn
        hoặc bất kỳ người nào dùng tài khoản của bạn theo các Điều khoản này
        hoặc phát sinh từ việc vi phạm các nghĩa vụ, tuyên bố và bảo đảm của bạn
        theo các Điều khoản này. Bạn hoàn toàn chịu trách nhiệm về các hành động
        của mình khi sử dụng Dịch vụ/Sản phẩm Metap.
      </div>
      <div className="my-4 font-bold text-lg">6. Điều khoản khác</div>
      <div className="my-4">Chính sách thanh toán </div>
      <div className="my-4">
        Sau khi đặt đơn hàng trên website của Metap, quý khách sẽ nhập các thông
        tin cá nhân giúp Metap có thể giao hàng thuận lợi. Chúng tôi chấp nhận
        thanh toán qua tài khoản ngân hàng. Sản phẩm của Metap chỉ được giao
        hàng tại Việt Nam, mọi đơn hàng đều được miễn phí phí giao hàng vận
        chuyển.
      </div>
      <div className="my-4 font-bold text-lg">Hướng dẫn sử dụng website</div>
      <div className="my-4">
        Bạn đồng ý và đảm bảo rằng, bạn có đủ năng lực pháp lý để sử dụng Dịch
        vụ của chúng tôi. Ngoài ra, bạn đảm bảo có đầy đủ hành vi dân sự để thực
        hiện các giao dịch mua bán hàng hóa theo quy định hiện hành của pháp
        luật Việt Nam.
      </div>
      <div className="my-4">
        Trong suốt quá trình đăng ký, quý khách đồng ý nhận email từ website.
        Email này giúp quý khách xác nhận thông tin đơn hàng.{" "}
      </div>
      <div className="my-4 font-bold text-lg">Luật áp dụng</div>
      <div className="my-4">
        Các Điều Khoản Dịch Vụ này sẽ được điều chỉnh bởi và diễn giải theo luật
        pháp của Việt Nam. Bất kỳ tranh chấp, tranh cãi, khiếu nại hoặc sự bất
        đồng dưới bất cứ hình thức nào phát sinh từ hoặc liên quan đến các Điều
        Khoản Dịch Vụ này chống lại hoặc liên quan đến CLĐ hoặc bất kỳ Bên Được
        Bồi Thường nào thuộc đối tượng của các Điều Khoản Dịch Vụ này sẽ được
        giải quyết tại Toà án có thẩm quyền của Việt Nam.{" "}
      </div>
      <div className="my-4 font-bold text-lg">Tính toàn bộ </div>
      <div className="my-4">
        Các Điều Khoản này cấu thành một thỏa thuận pháp lý hoàn chỉnh giữa bạn
        và Metap và điều chỉnh việc bạn sử dụng Dịch Vụ/Sản phẩm và thay thế
        hoàn toàn bất kỳ thỏa thuận nào trước đây giữa bạn và Metap liên quan
        đến Dịch Vụ/Sản phẩm.
      </div>
      <div className="my-4">
        Trừ khi được thiết lập rõ ràng trong đây, nếu bất cứ phần nào của thỏa
        thuận này được phát hiện là không thể thi hành và không hợp lệ, nó sẽ
        không ảnh hưởng đến tính hợp lệ của sự cân bằng của thỏa thuận, mà sẽ
        vẫn hợp lệ và đủ điều kiện theo các điều khoản của nó.
      </div>
      <div className="my-4 font-bold text-lg">Không khước từ</div>
      <div className="my-4">
        Việc chúng tôi không yêu cầu hoặc không thực thi bất kỳ quy định nào
        trong các Điều Khoản này sẽ không được giải thích là sự khước từ bất kỳ
        quy định hoặc quyền nào.
      </div>
      <div className="my-4 font-bold text-lg">Liên hệ với chúng tôi</div>
      <div className="my-4">
        Nếu bạn có bất kỳ câu hỏi nào liên quan đến các Điều khoản, hay muốn báo
        cáo vi phạm Thỏa thuận, hãy liên hệ dunglailaptrinh@gmail.com hoặc gọi
        0888421173.
      </div>
    </div>
  );
}
