import React from "react";
import { Router as ReachRouter } from "@reach/router";

import Dashboard from "./containers/Dashboard";
import LuxuryCard from "./containers/LuxuryCard";
import NotFoundPage from "./components/NotFoundPage";
import ViewProfile from "./ViewProfile";
import Layout from "./containers/Layout";
import Privacy from "./containers/Terms/Privacy";
import Service from "./containers/Terms/Service";
import Return from "./containers/Terms/Return";

const ScrollToTop = ({ children, location }) => {
  React.useEffect(() => {
    document.body.style.overflow = "visible";
    return window.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};
export default function Router() {
  return (
    <ReachRouter primary={false}>
      <ScrollToTop path="/">
        <Layout path="/terms">
          <Privacy path="/privacy" />
          <Service path="/service" />
          <Return path="/return" />
        </Layout>
        <Layout path="/">
          <Dashboard path="/" />
        </Layout>
        <Layout path="/luxury-card">
          <LuxuryCard path="/" />
        </Layout>

        <NotFoundPage path="/404" />
        <ViewProfile path="/:id" />
        <NotFoundPage path="*" />
      </ScrollToTop>
    </ReachRouter>
  );
}
