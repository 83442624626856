import React, { useState } from "react";
import LuxuryBack from "../../components/LuxuryBack";
import LuxuryFront from "../../components/LuxuryFront";

import ModalForm from "../Dashboard/ModalForm";

const defaultForm = {
  name: "",
  nameTag: "",
  phone: "",
  email: "",
  address: "",
  isWhite: false,
  type: 5,
};

export default function LuxuryCard({ location }) {
  const [isDrawer, setisDrawer] = useState(false);
  const [form, setform] = useState({ ...defaultForm });

  return (
    <div>
      <section id="section5" className="lg:pt-24 py-16">
        <div className="container">
          <div className="md:text-5xl text-3xl font-bold mb-8">
            <h2 className="text-center">
              <span className="text-6xl">Metap Luxury</span>
              <br />
              <span className="text-2xl font-semibold">
                Cá nhân hoá NameTag - In laser nhiệt - Phủ bề mặt
              </span>
            </h2>
          </div>
          <div className="flex flex-wrap">
            <div className="md:w-1/2 w-full">
              <div className="px-2">
                <LuxuryFront name={form.name} nameTag={form.nameTag} />
              </div>
            </div>
            <div className="md:w-1/2 w-full">
              <div className="px-2">
                <LuxuryBack name={form.name} nameTag={form.nameTag} />
              </div>
            </div>
          </div>
          <div
            className="pt-12 mx-auto"
            style={{
              maxWidth: 400,
            }}
          >
            <div className="pb-1">
              <span className="font-bold">Tên của bạn</span>
              <span
                className="text-sm float-right text-gray"
                style={{ color: "#ccc" }}
              >
                24 ký tự
              </span>
            </div>
            <div>
              <input
                value={form?.name}
                maxLength="24"
                onChange={(e) => setform({ ...form, name: e.target.value })}
                className="rounded-md border appearance-none w-full py-2 px-4 focus:outline-none"
                placeholder="Nhập tên của bạn"
              />
            </div>

            <div className="pb-1 mt-4">
              <span className="font-bold">Tuỳ chọn NameTag</span>
              <span
                className="text-sm float-right text-gray"
                style={{ color: "#ccc" }}
              >
                15 ký tự
              </span>
            </div>
            <div>
              <input
                value={form?.nameTag}
                maxLength="15"
                onChange={(e) => setform({ ...form, nameTag: e.target.value })}
                className="rounded-md border appearance-none w-full py-2 px-4 focus:outline-none"
                placeholder="Nhập nameTag của bạn"
              />
            </div>

            <div className="flex font-bold text-xl mt-2 justify-between items-center">
              <div className="flex">
                <p className="pr-4" style={{ color: "#42444A" }}>
                  499,000₫
                </p>
                <del className="text-md italic" style={{ color: "#ccc" }}>
                  599,000₫
                </del>
              </div>
              <div className="text-sm " style={{}}>
                Freeship toàn quốc
              </div>
            </div>
            <div className="pt-4">
              <button
                className="btn-datmua focus:outline-none m-auto"
                onClick={() => setisDrawer(true)}
              >
                Đặt mua
              </button>
            </div>
          </div>
          <div
            className="pt-8 mx-auto text-sm italic text-center"
            style={{ maxWidth: 630 }}
          >
            <div>
              Gợi ý đặt Nametag: Có thể là nickname, biệt danh, tên chức danh,
              khẩu hiệu, lời nhắn, tên công ty, vị trí, nghề nghiệp, tên thành
              phố, tên hãng yêu thích, tên món đồ yêu thích
            </div>
            <div className="pt-4">
              Ví dụ: SINGER, DEVELOPER, METAP, vinfast, ENGINEER, YSL, GUCCI,
              MERCEDES, Hồ Chí Minh, vietnam, lovely, lonely, …
            </div>
          </div>
        </div>
      </section>
      <ModalForm
        form={form}
        setform={setform}
        isDrawer={isDrawer}
        setisDrawer={setisDrawer}
        price={"499,999"}
        name="Thẻ Luxury"
        ads={location?.search}
      />
    </div>
  );
}
