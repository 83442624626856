import React from "react";

import { ReactComponent as infoCircle } from "../icons/info-circle.svg";
import { ReactComponent as Avatar } from "../icons/avatar.svg";
import { ReactComponent as Facebook } from "../icons/facebook.svg";
import { ReactComponent as Instagram } from "../icons/instagram.svg";
import { ReactComponent as FacebookF } from "../icons/facebook-f.svg";
import { ReactComponent as Tick } from "../icons/tick.svg";
import { ReactComponent as Times } from "../icons/times.svg";
import { ReactComponent as zalo } from "../icons/zalo.svg";
import { ReactComponent as youtube } from "../icons/youtube.svg";
import { ReactComponent as envelope } from "../icons/envelope.svg";
import { ReactComponent as phone } from "../icons/phone.svg";
import { ReactComponent as linkedinIn } from "../icons/linkedin-in.svg";
import { ReactComponent as tiktok } from "../icons/tiktok.svg";
import { ReactComponent as tiktokColor } from "../icons/tiktok-color.svg";
import { ReactComponent as snapchat } from "../icons/snapchat.svg";
import { ReactComponent as soundcloud } from "../icons/soundcloud.svg";
import { ReactComponent as twitter } from "../icons/twitter.svg";
import { ReactComponent as whatsapp } from "../icons/whatsapp.svg";
import { ReactComponent as spotify } from "../icons/spotify.svg";
import { ReactComponent as globe } from "../icons/globe.svg";
import { ReactComponent as demo } from "../icons/demo.svg";
import { ReactComponent as link } from "../icons/link.svg";
import { ReactComponent as stickyNote } from "../icons/sticky-note.svg";
import { ReactComponent as Eye } from "../icons/eye.svg";
import { ReactComponent as github } from "../icons/github-alt.svg";
import { ReactComponent as momo } from "../icons/momo.svg";
import { ReactComponent as telegram } from "../icons/telegram-plane.svg";
import { ReactComponent as vimeo } from "../icons/vimeo-v.svg";
import { ReactComponent as kakaotalk } from "../icons/kakao-talk.svg";
import { ReactComponent as flickr } from "../icons/flickr.svg";
import { ReactComponent as angleDown } from "../icons/angle-down.svg";

const icons = {
  tick: Tick,
  avatar: Avatar,
  facebook: Facebook,
  instagram: Instagram,
  times: Times,
  "facebook-f": FacebookF,
  info: infoCircle,
  zalo: zalo,
  youtube: youtube,
  envelope: envelope,
  phone: phone,
  linkedinIn: linkedinIn,
  tiktok: tiktok,
  tiktokColor: tiktokColor,
  snapchat: snapchat,
  soundcloud: soundcloud,
  twitter: twitter,
  whatsapp: whatsapp,
  spotify: spotify,
  globe: globe,
  link: link,
  demo: demo,
  "sticky-note": stickyNote,
  eye: Eye,
  github: github,
  telegram: telegram,
  momo: momo,
  vimeo: vimeo,
  kakaotalk: kakaotalk,
  flickr: flickr,
  "angle-down": angleDown,
};

export default function Icon({ type, ...rest }) {
  const Component = icons[type];

  if (!Component) {
    console.error(
      `The icon with type:${type} doesn't exist. Make sure you pass the existing one.`
    );
    return null;
  }
  return <Component fill="currentColor" {...rest} />;
}
