import React from "react";

export default class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.outerStyle = {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      overflow: "auto",
      zIndex: 1,
    };

    // default style
    this.style = {
      modal: {
        position: "relative",
        minWidth: 350,
        maxWidth: 500,
        width: "50%",
        padding: 20,
        boxSizing: "border-box",
        backgroundColor: "rgb(24, 24, 24)",
        margin: "100px auto",
        borderRadius: 3,
        zIndex: 2,
        textAlign: "left",
        boxShadow: "0 20px 30px rgba(0, 0, 0, 0.2)",
        overflowY: "auto",
        overflowX: "hidden",
        maxHeight: "calc(100vh - 200px)",
        // transition: "display 0.25s ease",
      },
      overlay: {
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.5)",
      },
    };
  }

  // render modal
  render() {
    // if (this.props.isModalOpen) {
    //   document.body.style.overflow = "hidden";
    // } else {
    //   document.body.style.overflow = "visible";
    // }
    return (
      <div
        style={{
          ...this.outerStyle,
          display: this.props.isModalOpen ? "block" : "none",
        }}
      >
        <div style={this.style.overlay} onClick={this.props.closeModal} />
        <div onClick={this.props.closeModal} />
        <div className="" style={{ ...this.style.modal, ...this.props.style }}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
