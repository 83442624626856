import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";
function Privacy() {
  return (
    <div className="container mx-auto my-8">
      <div className="my-8 text-2xl font-bold text-center">
        CHÍNH SÁCH BẢO MẬT
      </div>
      <div className="my-4 font-bold text-lg">I. GIỚI THIỆU </div>
      <div className="my-4">
        <span className="ml-8" />
        Metap là sản phẩm thẻ cá nhân thông minh cho phép bạn chia sẻ những kênh
        mạng xã hội của bạn cho người khác một cách nhanh chóng.
      </div>
      <div className="my-4">
        <span className="ml-8" />
        Việc bạn cài đặt bất kỳ Sản phẩm Metap nào và/hoặc sử dụng bất kỳ Dịch
        vụ Metap nào sẽ cấu thành sự chấp thuận của bạn đối với Chính Sách của
        chúng tôi và sự đồng ý hoàn toàn của bạn đối với phạm vi và các mục đích
        của chúng tôi trong việc sử dụng, xử lý và thu thập thông tin cá nhân
        của bạn như được mô tả tại đây.{" "}
      </div>
      <div className="my-4">
        <span className="ml-8" />
        Việc bạn cài đặt bất kỳ Sản phẩm Metap nào và/hoặc sử dụng bất kỳ Dịch
        vụ Metap nào sẽ cấu thành sự chấp thuận của bạn đối với Chính Sách của
        chúng tôi và sự đồng ý hoàn toàn của bạn đối với phạm vi và các mục đích
        của chúng tôi trong việc sử dụng, xử lý và thu thập thông tin cá nhân
        của bạn như được mô tả tại đây.
      </div>
      <div className="my-4">
        <span className="ml-8" />
        NẾU BẠN KHÔNG THỂ ĐỒNG Ý VỚI CÁC ĐIỀU KHOẢN CỦA CHÍNH SÁCH BẢO MẬT NÀY,
        VUI LÒNG KHÔNG TRUY CẬP HOẶC SỬ DỤNG CÁC SẢN PHẨM HOẶC DỊCH VỤ METAP.
      </div>
      <div className="my-4 font-bold text-lg">
        II. THÔNG TIN CHÚNG TÔI THU THẬP
      </div>
      <div className="my-4">
        <span className="ml-8" />
        Khi bạn truy cập website của metap, chúng tôi tự động lưu lại địa chỉ IP
        thiết bị bạn dùng để truy cập và thời gian bạn sử dụng website, việc này
        để phục vụ việc bảo vệ website khỏi nguy cơ bị tấn công. Ngoài ra, để
        cung cấp các Dịch vụ Metap cho bạn, chúng tôi sẽ thu thập các thông tin
        cơ bản về tài khoản của bạn sau đây
      </div>
      <div className="my-4">1. Thông tin phục vụ cho việc giao hàng</div>
      <div className="my-4">
        <span className="ml-8" />
        Chúng tôi yêu cầu người dùng khi đăng ký mua hàng phải cung cấp đầy đủ
        thông tin cá nhân sau đây: Họ và tên người nhận hàng, số điện thoại
        người nhận hàng, địa chỉ email người mua hàng, địa chỉ nhận hàng trong
        thời gian bạn sử dụng dịch vụ của chúng tôi.
      </div>
      <div className="my-4">2. Thông tin để đối chiếu giao dịch</div>
      <div className="my-4">
        <span className="ml-8" />
        Ngoài ra, chúng tôi có thể yêu cầu thông tin số tài khoản để đối chiếu
        giao dịch và xác nhận thanh toán.
      </div>
      <div className="my-4">
        <span className="ml-8" />
        Khi kê khai thông tin cá nhân, Quý khách cần đảm bảo mọi thông tin khai
        báo phải chính xác và hợp pháp, Chúng tôi không chịu mọi trách nhiệm
        pháp lý liên quan đến thông tin khai báo.
      </div>
      <div className="my-4 font-bold text-lg">
        III. MỤC ĐÍCH THU THẬP THÔNG TIN
      </div>
      <div className="my-4">
        Thông tin cá nhân thu thập được sẽ chỉ được Metap sử dụng trong nội bộ
        công ty và cho một hoặc tất cả các mục đích sau đây:
      </div>
      <div className="my-4">– Hỗ trợ khách hàng</div>
      <div className="my-4">– Cung cấp thông tin liên quan đến dịch vụ</div>
      <div className="my-4">
        – Xử lý đơn đặt hàng và cung cấp dịch vụ và thông tin qua trang web của
        chúng tôi theo yêu cầu của bạn: Chúng tôi sử dụng các thông tin Quý
        khách cung cấp để xử lý Đơn đặt hàng, cung cấp dịch vụ, thông tin về Sản
        phẩm.
      </div>
      <div className="my-4">
        – Chúng tôi có thể sẽ gửi thông tin sản phẩm, dịch vụ mới, thông tin về
        các sự kiện sắp tới hoặc thông tin tuyển dụng nếu quý khách đăng kí nhận
        email thông báo.
      </div>
      <div className="my-4">
        - Chúng tôi cũng sử dụng thông tin mà chúng tôi nhận được để phát triển
        và cải thiện hơn nữa chức năng cũng như tính năng của Dịch vụ Metap, ví
        dụ: cải thiện bố cục, nội dung trang web và điều chỉnh cho phù hợp với
        người dùng.
      </div>
      <div className="my-4">
        – Ngoài ra, chúng tôi sẽ sử dụng thông tin bạn cung cấp để hỗ trợ quản
        lý tài khoản khách hàng; xác nhận và thực hiện các giao dịch tài chính
        liên quan đến các khoản thanh toán trực tuyến của bạn;
      </div>
      <div className="my-4 font-bold text-lg">
        IV. THỜI GIAN LƯU TRỮ THÔNG TIN{" "}
      </div>
      <div className="my-4">
        <span className="ml-8" />
        Dữ liệu cá nhân của bạn sẽ được lưu trữ cho đến khi có yêu cầu huỷ bỏ
        hoặc tự người dùng đăng nhập và thực hiện các thao tác huỷ.
      </div>
      <div className="my-4">
        <span className="ml-8" />
        Người dùng có quyền yêu cầu chúng tôi xóa tài khoản của bạn. Mọi thông
        tin liên quan đến tài khoản metap cá nhân bao gồm các kênh mạng xã hội
        và thông tin khi bạn đặt đơn hàng tại website của metap đều được xóa bỏ
        hoàn toàn khỏi hệ thống trong vòng 48 tiếng kể từ khi yêu cầu quyết định
        ngừng sử dụng dịch vụ của metap được chúng tôi xác nhận và chứng thực.
      </div>
      <div className="my-4">
        <span className="ml-8" />
        Người dùng có quyền tự kiểm tra, cập nhật, điều chỉnh hoặc hủy bỏ thông
        tin cá nhân của mình bằng cách đăng nhập vào tài khoản và chỉnh sửa
        thông tin cá nhân.
      </div>
      <div className="my-4 font-bold text-lg">
        V. CHIA SẺ DỮ LIỆU VỚI BÊN THỨ BA
      </div>
      <div className="my-4">
        Metap không bán, chia sẻ hay trao đổi thông tin cá nhân của khách hàng
        thu thập trên trang web cho một bên thứ ba nào khác. Thông tin cá nhân
        của người dùng được chúng tôi cam kết bảo mật tuyệt đối theo chính sách
        bảo vệ thông tin cá nhân của Metap. Việc thu thập và sử dụng thông tin
        của mỗi thành viên chỉ được thực hiện khi có sự đồng ý của khách hàng đó
        trừ những trường hợp pháp luật có quy định khác. Dưới đây là các loại
        bên thứ ba mà chúng tôi chia sẻ thông tin:
      </div>
      <div className="my-4 font-bold text-lg">
        <span className="ml-8" />
        Vì lý do pháp lý
      </div>
      <div className="my-4">
        <span className="ml-8" />
        Chúng tôi chia sẻ thông tin với Cơ quan nhà nước có thẩm quyền hoặc theo
        yêu cầu pháp lý trong những trường hợp dưới đây:
      </div>
      <div className="my-4">
        <span className="ml-8" />
        +) Do pháp luật quy định hoặc theo yêu cầu pháp lý (yêu cầu của Cơ quan
        điều tra, yêu cầu, quyết định của Tòa án)
      </div>
      <div className="my-4">
        <span className="ml-8" />
        +) Khi chúng tôi thực sự tin rằng cần phải: phát hiện, ngăn chặn và xử
        lý hành vi gian lận, hành vi sử dụng trái phép Dịch vụ Metap, hành vi vi
        phạm các điều khoản hoặc chính sách của chúng tôi hay hoạt động phi pháp
        hoặc có hại khác; để bảo vệ chính chúng tôi (gồm quyền, tài sản hoặc Sản
        phẩm/Dịch vụ của chúng tôi) trước các khiếu nại hoặc hành động pháp lý
        chống lại chúng tôi, hoặc bảo vệ sự an toàn, quyền hoặc tài sản của
        Metap hoặc bất kỳ bên thứ ba nào; để bảo vệ bạn hoặc những người khác,
        kể cả trong quá trình điều tra các vi phạm tiềm ẩn trong đó, hoặc để
        giải quyết các vấn đề liên quan đến hoạt động bất hợp pháp, thực tế hoặc
        bị nghi ngờ, hay các vi phạm bảo mật.
      </div>
      <div className="my-4 font-bold text-lg">
        <span className="ml-8" />
        Nhà cung cấp dịch vụ bên thứ ba
      </div>
      <div className="my-4">
        <span className="ml-8" />
        Chúng tôi chia sẻ thông tin được thu thập tự động (như mô tả ở trên) với
        các nhà cung cấp dịch vụ bên thứ ba và các công ty liên kết của chúng
        tôi, những người giúp chúng tôi cung cấp các Sản phẩm/Dịch vụ của CLĐ
        dựa trên hướng dẫn và tuân theo Chính sách bảo mật của chúng tôi cũng
        như mọi biện pháp bảo mật và an toàn thích hợp khác.
      </div>
      <div className="my-4">
        <span className="ml-8" />
        Trong trường hợp máy chủ lưu trữ thông tin bị hacker tấn công dẫn đến
        mất mát dữ liệu cá nhân thành viên, chúng tôi sẽ có trách nhiệm thông
        báo vụ việc cho cơ quan chức năng điều tra xử lý kịp thời và thông báo
        cho thành viên được biết.
      </div>
      <div className="my-4">
        <span className="ml-8" />
        Mỗi thẻ cá nhân thông minh Metap đều có một mã QR được in lên thẻ và mã
        gắn trong chip đặt bên trong chiếc thẻ, mã này sẽ dẫn đến đường link
        website trang cá nhân Metap của bạn. Thông tin mạng xã hội và thông tin
        liên lạc bạn cài đặt lên trang cá nhân Metap của bạn là hoàn toàn tự
        nguyện. Đây là thông tin mà bất kì ai đều có thể thấy được qua đường
        link đến trang cá nhân metap của bạn. Chúng tôi không có trách nhiệm bảo
        vệ những thông tin này.
      </div>
      <div className="my-4">
        <span className="ml-8" />
        Đối với những tài khoản mạng xã hội bạn cài đặt lên trang cá nhân Metap
        của mình, Metap chỉ yêu cầu đường link dẫn đến trang cá nhân đó. Metap
        tuyệt đối không yêu cầu quyền truy cập hay mật khẩu đến bất kì tài khoản
        mạng xã hội nào của bạn. Chúng tôi chịu trách nhiệm về tính pháp lý của
        những thông tin trên. Ban quản lý Metap không chịu trách nhiệm cũng như
        không giải quyết mọi khiếu nại có liên quan đến quyền lợi của các cá
        nhân nếu xét thấy tất cả thông tin cá nhân của người dùng đó cung cấp
        khi đăng ký ban đầu là không chính xác.
      </div>
      <div className="my-4 font-bold text-lg">
        <span className="ml-8" />
        Được bạn chấp thuận
      </div>
      <div className="my-4">
        <span className="ml-8" />
        Khi có sự đồng ý của bạn, chúng tôi sẽ chia sẻ thông tin cá nhân ra bên
        ngoài Metap. Chúng tôi sẽ xin phép bạn rõ ràng trước khi chia sẻ bất kỳ
        thông tin cá nhân nhạy cảm nào.
      </div>
      <div className="my-4 font-bold text-lg">
        <span className="ml-8" />
        Chuyển giao kinh doanh
      </div>
      <div className="my-4">
        <span className="ml-8" />
        Trong trường hợp có giao dịch công ty (ví dụ: bán một phần quan trọng
        của dự án của chúng tôi hoặc sáp nhập hay hợp nhất với một pháp nhân
        khác), bao gồm cả trong quá trình thương lượng về giao dịch đó. Chúng
        tôi sẽ cố gắng yêu cầu pháp nhân đạt được hoặc giữ lại các quyền và
        nghĩa vụ như đã được mô tả trong Chính sách bảo mật này.
      </div>
      <div className="my-4 font-bold text-lg">VI. CẬP NHẬT VÀ SỬA ĐỔI</div>
      <div className="my-4">
        <span className="ml-8" />
        Nội dung của "Điều khoản bảo mật" này có thể thay đổi để phù hợp với các
        nhu cầu của Chúng tôi cũng như nhu cầu và sự phản hồi từ khách hàng. Khi
        cập nhật nội dung chính sách này, chúng tôi sẽ chỉnh sửa lại thời gian
        "Cập nhật lần cuối". Với những thay đổi quan trọng, chúng tôi sẽ thông
        báo cho bạn qua các kênh mạng xã hội cũng như thông báo trên website
        chính thức.
      </div>
      <div className="my-4 font-bold text-lg">VII. LIÊN HỆ</div>
      <div className="my-4">
        <span className="ml-8" />
        Khi phát hiên thông tin cá nhân của mình bị sử dụng sai mục đích hoặc
        phạm vi, khách hàng vui lòng gửi email khiếu nại đến email:
        dunglailaptrinh@gmail.com hoặc gọi điện thoại tới 0888421173 để khiếu
        nại và cung cấp chứng cứ liên quan tới vụ viêc cho Ban quản trị. Chúng
        tôi cam kết sẽ phản hồi ngay lập tức hoặc muộn nhất là trong vòng 48 giờ
        làm việc kể từ thời gian nhận được khiếu nại.
      </div>
    </div>
  );
}

export default Privacy;
