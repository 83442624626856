import React from "react";
import Icon from "./Icon";
import image from "../images/luxury-back.png";

export default function LuxuryBack({ name, nameTag }) {
  return (
    <div className="lg:pl-16 pl-0 text-white flex-col flex justify-center items-center mb-4">
      <div style={{ maxWidth: 450, maxHeight: 300 }} className="relative">
        <img
          src={image}
          className="m-auto rounded w-full h-full"
          alt="2"
          style={{ boxShadow: "0 0 10px #dfdfdf" }}
        />
        <div
          className={`absolute font-bold md:text-lg text-md`}
          style={{
            color: "white",
            right: "36%",
            bottom: 0,
            top: "-44%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            wordBreak: "break-word",
          }}
        >
          {nameTag}
        </div>
        <div
          className={`absolute font-bold  md:text-lg text-md`}
          style={{
            color: "white",
            left: "18%",
            bottom: 0,
            top: "30%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            wordBreak: "break-word",
          }}
        >
          {name}
        </div>
      </div>

      <div
        className="pt-4 flex items-center justify-center"
        style={{ maxWidth: 400, color: "black" }}
      >
        <div className="pr-1">
          <Icon type="info" width={20} />
        </div>
        Mặt Sau
      </div>
    </div>
  );
}
