import React, {useEffect, useState} from "react";
import ModalForm from "./ModalForm";
const defaultForm = {
	name: "",
	phone: "",
	email: "",
	address: "",
	isWhite: false,
	type: 2
}
function App({location}) {
	const [form, setForm] = useState({ ...defaultForm });

	return (
	<>
		<ModalForm
		form={form}
		setForm={setForm}
		/>
	</>
	)
}

export default App;
