import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { navigate, Link } from "@reach/router";
import Icon from "../components/Icon";

import IconMedia from "../components/IconMedia";
import { Helmet } from "react-helmet";

const STATIC_IMAGES = process.env.REACT_APP_CORE_API + "images/thumb-";

const ChannelMediaListDefault = {
  facebook: {
    hyperLink: "https://m.me/",
    title: "Facebook",
    type: "facebook-f",
    bgColor: "#1d4b99",
  },
  instagram: {
    hyperLink: "https://instagram.com/",
    title: "Instagram",
    type: "instagram",
    bgColor: "#e1306c",
  },
  whatsapp: {
    hyperLink: "https://wa.me/",
    title: "Whatsapp",
    type: "whatsapp",
    bgColor: "#37b726",
  },
  zalo: {
    hyperLink: "https://zalo.me/",
    title: "Zalo",
    type: "zalo",
    bgColor: "#fff",
  },
  youtube: {
    hyperLink: "https://www.youtube.com/channel/",
    title: "Youtube",
    type: "youtube",
    bgColor: "red",
  },
  email: {
    hyperLink: "mailto:",
    title: "Email",
    type: "envelope",
    show: true,
    bgColor: "#007eff",
  },
  phone: {
    hyperLink: "tel:",
    title: "Phone",
    type: "phone",
    show: true,
    bgColor: "#000000",
  },
  linkedin: {
    hyperLink: "https://linkedin.com/in/",
    title: "Linkedin",
    type: "linkedinIn",
    bgColor: "#0275b4",
  },
  tiktok: {
    hyperLink: "https://www.tiktok.com/@",
    title: "Tiktok",
    type: "tiktokColor",
    bgColor: "#000",
  },
  snapchat: {
    hyperLink: "https://snapchat.com/add/",
    title: "Snapchat",
    type: "snapchat",
    bgColor: "rgb(248 245 0)",
  },
  soundcloud: {
    hyperLink: "https://soundcloud.com/",
    title: "Soundcloud",
    type: "soundcloud",
    bgColor: "rgb(248 83 0)",
  },
  twitter: {
    hyperLink: "https://twitter.com/",
    title: "Twitter",
    type: "twitter",
    bgColor: "#1c9deb",
  },
  spotify: {
    title: "Spotify",
    type: "spotify",
    bgColor: "#2ebd59",
    hyperLink: "https://open.spotify.com/user/",
  },
  website: {
    hyperLink: "",
    title: "Website",
    type: "link",
    bgColor: "#000000",
  },
  custom: {
    title: "Tùy chọn",
    placeholder: "URL Website, ví dụ https://metap.vn",
    type: "sticky-note",
    bgColor: "#000000",
    show: true,
  },
  vimeo: {
    title: "Vimeo",
    type: "vimeo",
    bgColor: "#00a8e8",
    hyperLink: "https://vimeo.com/",
  },
  github: {
    title: "Github",
    type: "github",
    bgColor: "black",
    hyperLink: "https://github.com/",
  },
  telegram: {
    title: "Telegram",
    type: "telegram",
    bgColor: "#28a3e4",
    hyperLink: "https://t.me/",
  },
  flickr: {
    title: "Flickr",
    type: "flickr",
    bgColor: "white",
    hyperLink: "https://www.flickr.com/people/",
  },
  momo: {
    title: "Ví Momo",
    type: "momo",
    bgColor: "#a50064",
    hyperLink: "https://nhantien.momo.vn/",
    style: { padding: 5 },
  },
};

function nFormatter(num, digits) {
  var si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

const Container = styled.div`
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
`;
export default function ViewProfile({ location }) {
  const [profile, setprofile] = useState(null);
  useEffect(() => {
    fetchProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchProfile = async () => {
    const username = location?.pathname.slice(1, location?.pathname.lennth);
    try {
      await fetch(process.env.REACT_APP_CORE_API + "profile/" + username)
        .then((resp) => {
          console.log(resp);
          if (resp.status !== 200) {
            return navigate("/404");
          }
          return resp.json();
        })
        .then(function (data) {
          setprofile(data.data);
        });
    } catch (error) {
      console.log(error);
      navigate("/404");
    }
  };

  const downloadProfile = async () => {
    const username = location?.pathname.slice(1, location?.pathname.length);
    document.location.href =
      process.env.REACT_APP_CORE_API + "profile/" + username + "/download";
    // try {
    //   await fetch(
    //     process.env.REACT_APP_CORE_API + "profile/" + username + "/download"
    //   )
    //     .then((resp) => {
    //       if (resp.status !== 200) {
    //         return navigate("/404");
    //       }
    //       document.location.href = resp;
    //       // return resp.json();
    //     })
    //     .then(function (data) {
    //       // var url =
    //       //   "data:text/x-vcard;charset=utf-8," + encodeURIComponent(data.data);
    //       // document.location.href = url;

    //       // const url = window.URL.createObjectURL(
    //       //   new Blob([data.data], { type: "application/octet-stream" })
    //       // );
    //       // const link = document.createElement("a");
    //       // link.target = "_blank";
    //       // link.href = url;
    //       // // link.setAttribute("download", data.fileName);
    //       // document.body.appendChild(link);
    //       // link.click();
    //     });
    // } catch (error) {
    //   console.log(error);
    // }
  };

  if (!profile) {
    return (
      <div
        className="min-h-screen p-4 text-white"
        style={{ backgroundColor: "#181818" }}
      />
    );
  }

  const { images, fullName, bio, channels } = profile;

  const titleShow = (i, idx) => {
    if (i.name === "custom") {
      return i.title ? i.title : "Custom";
    }

    if (ChannelMediaListDefault[i.name]?.title === "Website") {
      if (i.title) {
        return i.title;
      }
      return channels[idx]?.value;
    }

    if (ChannelMediaListDefault[i.name]?.title === "Phone") {
      return i.title ? i.title : "Phone";
    }

    return ChannelMediaListDefault[i.name]?.title;
  };

  return (
    <div
      className="min-h-screen text-white"
      style={{ backgroundColor: "#181818" }}
    >
      {fullName ? (
        <Helmet>
          <meta charSet="utf-8" />
          <title>metap - {fullName}</title>
        </Helmet>
      ) : null}

      <Container>
        <div className="flex p-4 ">
          {images ? (
            <img
              className="rounded-full md:w-40 md:h-40 w-32 h-32 object-cover"
              src={STATIC_IMAGES + images}
              alt="logo"
            />
          ) : (
            <div>
              <Icon type="avatar" style={{ height: 160, width: "auto" }} />
            </div>
          )}
          <div className="md:pl-8 pl-4 md:pt-8 pt-1">
            <div
              className="text-3xl font-bold"
              style={{ wordBreak: "break-word" }}
            >
              {fullName ? fullName : <>&nbsp;</>}
            </div>
            <div style={{ wordBreak: "break-word" }}>{bio}</div>
            <div>
              <button
                className="md:hidden block w-36 px-4 py-1 my-2 rounded-md focus:outline-none font-bold"
                style={{ backgroundColor: "#319aff" }}
                onClick={downloadProfile}
              >
                Thêm danh bạ
              </button>
            </div>
          </div>
        </div>
      </Container>

      <div>
        {channels.map((i, idx) => (
          <a
            target={i.name === "custom" ? "" : "_blank"}
            href={
              i.name === "custom"
                ? "javascript:void(0)"
                : ChannelMediaListDefault[i.name]?.hyperLink + i.value
            }
            key={idx}
            className="px-5 py-4 border-t-3 border-black block text-white"
            style={{ minHeight: 68 }}
          >
            <Container className="flex items-center">
              <IconMedia
                height={70}
                width={70}
                type={ChannelMediaListDefault[i.name]?.type}
                bgColor={ChannelMediaListDefault[i.name]?.bgColor}
                style={ChannelMediaListDefault[i.name]?.style}
              />
              <div className="pl-3">
                <div className="font-bold text-xl">{titleShow(i, idx)}</div>
                {ChannelMediaListDefault[i.name]?.show ? (
                  <div>{channels[idx]?.value}</div>
                ) : null}
              </div>
            </Container>
          </a>
        ))}
      </div>

      <div className="pt-8 pb-8 px-4">
        <div className="flex justify-center">
          {/* <img
            className=""
            src={logoMetap}
            style={{ width: 205, maxHeight: 70 }}
            alt="logo"
          /> */}
          <div
            className="font-bold text-5xl text-white px-8 "
            style={{ letterSpacing: -5, lineHeight: 1 }}
          >
            metap
          </div>
        </div>
        <div className="text-center py-2 px-2 font-semibold text-sm">
          Thẻ thông minh{" "}
          <Link className="" style={{ color: "#38d39f" }} to="/">
            Metap
          </Link>
        </div>
        <div className="flex justify-center">
          <div className="text-sm text-center" style={{ maxWidth: 400 }}>
            Thẻ cá nhân thông minh đầu tiên tại việt nam
          </div>
        </div>
        {/* <div className="flex justify-center items-center">
          <Icon
            type="eye"
            className="rounded-md flex justify-center items-center p-1"
            style={{
              width: 30,
              height: 30,
              color: "#fff",
            }}
          />{" "}
          {nFormatter(profile?.count)}
        </div> */}
      </div>
    </div>
  );
}
