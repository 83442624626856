import React from "react";
import Icon from "./Icon";
import image from "../images/luxury-front.png";

export default function LuxuryFront({ name, nameTag }) {
  return (
    <div className="lg:pl-16 pl-0 text-white flex-col flex justify-center items-center mb-4">
      <div style={{ maxWidth: 450, maxHeight: 300 }} className="relative">
        <img
          src={image}
          className="m-auto rounded w-full h-full"
          alt="2"
          style={{ boxShadow: "0 0 10px #dfdfdf" }}
        />
        <div
          className={`absolute font-semibold lg:text-sm text-xs`}
          style={{
            color: "white",
            left: 0,
            bottom: 0,
            top: "-33%",
            right: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            wordBreak: "break-word",
            letterSpacing: 5,
          }}
        >
          {nameTag}
        </div>
        <div
          className={
            `absolute font-bold ` +
            (name.length > 12 ? "lg:text-lg text-xs" : "text-2xl")
          }
          style={{
            color: "white",
            left: 0,
            bottom: 0,
            top: -10,
            right: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

            wordBreak: "break-word",
          }}
        >
          {name}
        </div>
      </div>

      <div
        className="pt-4 flex items-center justify-center"
        style={{ maxWidth: 400, color: "black" }}
      >
        <div className="pr-1">
          <Icon type="info" width={20} />
        </div>
        Mặt Trước
      </div>
    </div>
  );
}
