import React from "react";

export default function Return() {
  return (
    <div className="container mx-auto my-8">
      <div className="my-8 text-2xl font-bold text-center">
        CHÍNH SÁCH ĐỔI TRẢ
      </div>
      <div className="my-4">
        Các điều khoản và điều kiện được quy định trong Chính sách đổi trả này
        tạo thành một phần của Điều khoản dịch vụ, Metap đảm bảo quyền lợi của
        Người mua bằng cách cho phép gửi yêu cầu hoàn trả sản phẩm và/hoặc đổi
        hàng.
      </div>
      <div className="my-4 font-bold text-lg">1. Điều kiện đổi trả</div>
      <div className="my-4">
        <div>
          Người mua cần kiểm tra tình trạng hàng hóa ngay tại thời điểm
          giao/nhận hàng và đồng ý rằng mình chỉ có thể đổi hàng/trả lại hàng
          trong những trường hợp sau:
        </div>
        <div>
          <ul>
            <li className="">
              <span className="pl-4"></span>• Hàng không đúng chủng loại, mẫu mã
              trong đơn hàng đã đặt hoặc như trên website tại thời điểm đặt
              hàng.
            </li>
            <li className="">
              <span className="pl-4"></span>• Tình trạng bên ngoài bị ảnh hưởng
              như trầy xước, bong tróc, bể vỡ Người mua có trách nhiệm trình các
              bằng chứng, giấy tờ, tài liệu liên quan chứng minh sự thiếu sót
              trên để hoàn thành việc hoàn trả/đổi trả hàng hóa.
            </li>
          </ul>
        </div>
      </div>

      <div className="my-4 font-bold text-lg">
        2. Tình trạng của hàng trả lại
      </div>
      <div className="my-4">
        Để hạn chế các rắc rối phát sinh liên quan đến đổi/trả hàng, Người mua
        lưu ý cần phải gửi trả sản phẩm nguyên trạng như khi nhận hàng, bao gồm
        toàn bộ phụ kiện đi kèm nếu có. Metap khuyến khích Người mua quay video
        hoặc chụp lại ảnh sản phẩm ngay khi nhận được để làm bằng chứng đối
        chiếu/khiếu nại về sau nếu cần.
      </div>
      <div className="my-4 font-bold text-lg">
        3. Thời gian thông báo và gửi sản phẩm đổi trả
      </div>
      <div className="my-4">
        <span className="italic pl-4">Thời gian thông báo đổi trả:</span> trong
        vòng 48h kể từ khi nhận sản phẩm đối với trường hợp sản phẩm thiếu phụ
        kiện, bong tróc hoặc bể vỡ. Đối với những trường hợp khác, quý khách vui
        lòng liên hệ với Metap theo thông tin liên hệ ở dưới điều khoản để chúng
        tôi giải đáp các câu hỏi, trục trặc, và đồng thời sẽ sắp xếp việc gửi
        lại hàng để kiểm tra. Sau khi kiểm tra, nếu sản phẩm có lỗi, chúng tôi
        sẽ gửi sản phẩm thay thế tưởng ứng, nếu sản phẩm không có lỗi, chúng tôi
        sẽ đóng gói và gửi lại bạn.
      </div>
      <div className="my-4">
        <span className="italic pl-4">Thời gian gửi chuyển trả sản phẩm:</span>{" "}
        trong vòng 14 ngày kể từ khi nhận sản phẩm.
      </div>
      <div>
        <span className="italic pl-4">Địa điểm đổi trả sản phẩm:</span> Người
        mua có thể mang hàng trực tiếp đến địa chỉ của chúng tôi hoặc chuyển qua
        đường bưu điện.
      </div>
      <div className="my-4 font-bold text-lg">
        4. Quy định về hỗ trợ sử dụng
      </div>
      <div className="my-4">
        Trước khi gửi lại sản phẩm, Người mua cần liên hệ với chúng tôi bằng một
        trong những kênh sau đây để chúng tôi kiểm định sản phẩm và hướng dẫn
        quý khách nếu gặp trục trặc.
      </div>
      <div>Thông tin liên hệ:</div>
      <div>Fanpage facebook Thẻ Thông Minh Metap hoặc Dũng Lại Lập Trình</div>
      <div>Email: dunglailaptrinh@gmail.com </div>
      <div>Số điện thoại: 0888421173 (Mrs. Hương) </div>
    </div>
  );
}
