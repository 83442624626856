import React from "react";
import "./index.css";

export default function NotFoundPage() {
  return (
    <section
      className="min-h-screen flex justify-center items-center p-4 text-white"
      style={{ backgroundColor: "#181818" }}
    >
      <div className="wrap-error text-center">
        <div className="header-error">404</div>
        <div className="content-error">
          <h2>Vui lòng đăng kí theo hướng dẫn kèm theo trước khi sử dụng</h2>
          <p>
            Bạn chưa đăng kí tài khoản, vui lòng dùng mã metap và đăng kí theo hướng dẫn được gửi kèm theo thẻ. Bấm {" "}
            <a href="/" className="btn-error">
              vào đây
            </a>{" "}
            để quay lại trang chủ Metap
          </p>
        </div>
      </div>
    </section>
  );
}
